// font-size + line-height
@mixin font-size($font-size, $line-height) {
    font-size: $font-size + px;
    font-size: ($font-size / 10) * 1rem;
    line-height: ($line-height / $font-size);
}

// (no) list-style
@mixin no-list-style {
    margin: 0;
    padding: 0;
    list-style: none;
}

// uppercase
@mixin uppercase($letter-spacing:1) {
    text-transform: uppercase;
    letter-spacing: $letter-spacing * 1px;
}

// center-block
@mixin center-block {
    display: block;
    margin: 0 auto;
}

// clearfix
@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// button 
@mixin button($font:inherit, $font-size: inherit, $line-height:inherit, $color: $white, $font-weight: 400, $topbottom: 6, $leftright: 30, $border-style:solid, $border-color:$black, $border-width:1, $border-radius: 0, $bg-color: $black, $cursor: pointer) {
    font-family: $font;
    font-size: $font-size * 1px;
    font-size: ($font-size / 10) * 1rem;
    line-height: ($line-height / $font-size);
    color: $color;
    font-weight: $font-weight;
    padding: ($topbottom / $font-size) * 1em ($leftright / $font-size) * 1em;
    border-style: $border-style;
    border-color: $border-color;
    border-width: $border-width * 1px;
    border-radius: $border-radius * 1px;
    background-color: $bg-color;
    cursor: $cursor;
}

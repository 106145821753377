/* Navigation */

.menu-btn {
    @include button($font: inherit, $font-size: 32, $line-height:1, $color: $lightblue, $font-weight: 400, $topbottom: 0, $leftright: 0, $border-style: none, $border-color: transparent, $border-width: 0, $border-radius: 0, $bg-color: transparent, $cursor: pointer);
    float: right;
    margin: 5px 1% 0 0;
}

.header__nav {
    clear: both;
    margin-left: -20px;
    margin-right: -20px;
}

.menu {
    display: none;
    @include uppercase;
    @include no-list-style;
    text-align: center;
    
    &__item {
        background: lighten($silver, 5%);
    }
    &__link {
        display: block;
        @include font-size(14, 40);
        color: $lightgrey;
        border-bottom: 1px solid darken($silver, 7%);
        text-decoration: none;
        
        &.nav-active {
            background: $lightblue;
        }
    }
}

@include breakpoint($large) {
    .menu-btn {
        display: none;
    }
    .header__nav {
        clear: none;
        float: right;
        margin-left: 0;
        margin-right: 0;
    }
    .menu {
        display: block !important; // forces menu to show
        border-top: none; // overriding previous styles
        &__item {
            float: left;
            background: none;
            padding-right: 40px;
            &:last-child {
                padding-right: 0;
            }
        }
        &__link {
            font-size: 14px;
            line-height: normal;
            padding: 20px 0 29px;
            color: $lightblue;
            border-bottom: none;
            font-weight: 500;
            text-decoration: none;
            padding-left: 20px;
            padding-right: 20px;
            &.nav-active {
                background: transparent;
                border-bottom: 3px solid $lightblue;
            }
        }
    }
}

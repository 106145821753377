/* Carousel */

.img-responsive,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rounded {
    border-radius: 6px;
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.img-circle {
    border-radius: 50%;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

[role="button"] {
    cursor: pointer;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.carousel-inner>.item {
    display: none;
    position: relative;
    transition: 0.6s ease-in-out left;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    line-height: 1;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .carousel-inner>.item {
        transition: transform 0.6s ease-in-out;
        backface-visibility: hidden;
        perspective: 1000px;
    }
    .carousel-inner>.item.next,
    .carousel-inner>.item.active.right {
        transform: translate3d(100%, 0, 0);
        left: 0;
    }
    .carousel-inner>.item.prev,
    .carousel-inner>.item.active.left {
        transform: translate3d(-100%, 0, 0);
        left: 0;
    }
    .carousel-inner>.item.next.left,
    .carousel-inner>.item.prev.right,
    .carousel-inner>.item.active {
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
    display: block;
}

.carousel-inner>.active {
    left: 0;
}

.carousel-inner>.next,
.carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner>.next {
    left: 100%;
}

.carousel-inner>.prev {
    left: -100%;
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
    left: 0;
}

.carousel-inner>.active.left {
    left: -100%;
}

.carousel-inner>.active.right {
    left: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5%;
    color: $black;
    text-align: center;
}

.carousel-control.left {}

.carousel-control.right {
    left: auto;
    right: 0;
}

.carousel-control:hover,
.carousel-control:focus {}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 999; //custom
    display: inline-block;
    font-size: 24px; // custom
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -40%;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -40%;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
}


/*start custom */

.carousel-indicators {
    display: none; // custom
    position: absolute;
    top: 15%;
    //bottom: 10px;
    left: 3%;
    z-index: 15;
    width: 35%;
    height: 60px;
    //margin-left: -30%;
    list-style: none;
    //text-align: center;
    margin: 0; //custom 
    padding: 25px;
    @include breakpoint($xlarge) {
        display: block;
    }
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid $black;
    border-radius: 10px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: rgba(0, 0, 0, 0);
}

.carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: $black;
}


/*end custom */


/* start custom */

.carousel-caption {
    display: none; // custom
    position: absolute;
    top: 15%;
    left: 3%;
    width: 35%;
    height: auto;
    z-index: 10;
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.9);
    @include breakpoint($xlarge) {
        display: block;
    }
}


/* end custom */

.carousel-caption .btn {
    text-shadow: none;
}

@media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-prev,
    .carousel-control .icon-next {
        width: auto; //custom
        height: 30px;
        margin-top: -10px;
        font-size: 30px;
    }
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -40%;
    }
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
        margin-right: -40%;
    }
    /*.carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }*/
    .carousel-indicators {
        bottom: 20px;
    }
}

// custom
.carousel-caption h2 {
    @include font-size(16, 20);
    font-weight: 300;
    @include uppercase(0);
    margin-top: 60px;
    @include breakpoint($large) {
        @include font-size(24, 30);
    }
}

.cta-btn {
    display: inline-block;
    @include button($font: inherit, $font-size:12, $line-height:20, $color: $black, $font-weight: 500, $topbottom: 5, $leftright: 10, $border-style: solid, $border-color: $darkgrey, $border-width: 2, $border-radius: 3, $bg-color: transparent, $cursor: pointer);
    text-decoration: none;
    @include uppercase(1);
}

.carousel {
    margin-left: -20px;
    margin-right: -20px;
}

// make sure respnosive images are scaled correctly on orientation change
img[data-sizes="auto"] {
    display: block;
    width: 100%;
}

@import "../layout/grid";

/* Base
   ========================================================================== */

html {
    box-sizing: border-box;
    font-size: 62.5%;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $font__main;
    @include font-size(13, 20);
    color: $black;
    background: $lightgrey;
    overflow-x: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

p {
    margin-bottom: 20px;
}

b,
strong {
    font-weight: 500;
}

h2 {
    @include font-size(30, 40);
    font-weight: 400;
    margin-bottom: 20px;
    @include breakpoint($large) {
        @include font-size(36, 40);
    }
}

h3 {
    @include font-size(24, 40);
    font-weight: 400;
    margin-bottom: 20px;
}

a {
    color: inherit;
    text-decoration: none;
    &:visited {}
    &:focus {}
    &:hover {}
    &:active {}
}

//overwrite styling in ios safari
a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

img {
    height: auto;
    /* Make sure images are scaled correctly. */
    max-width: 100%;
    /* Adhere to container width. */
    width: auto\9;
    /* ie8 */
}

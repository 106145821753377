.container {
    @include container;
    padding: 0 20px;
}


/* Header
   ========================================================================== */

.header {
    @include clearfix;
    &__contact {
        margin-left: -9999em;
        margin-right: -9999em;
        padding-left: 9999em;
        padding-right: 9999em;
        color: $lightgrey;
        text-align: right;
        margin-bottom: 20px;
        background: $lightblue;
        @include breakpoint($small) {
            margin-bottom: 40px;
        }
    }
    &__logo {
        float: left;
        margin-bottom: 20px;
        @include breakpoint($small) {
            margin-bottom: 30px;
        }
        .logo {
            height: 48px;
            @include breakpoint($small) {
                height: auto;
            }
        }
    }
}

@include breakpoint($large) {
    header {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }
    .sticky-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        width: 100%;
        height: 160px;
        overflow: hidden;
        transition: all 0.3s;
        .header__contact {
            transition: margin-bottom 0.3s;
        }
        .logo {
            height: 57px;
            transition: height 0.3s;
        }
        &.smaller {
            height: 110px;
            overflow: hidden;
            background: $lightgrey;
            background-color: $lightgrey;
            border-bottom: 1px solid $silver;
            box-shadow: 0px 0px 2px 0px rgba(192, 192, 192, 1); //silver       
            transition: all 0.5s;
            .header__contact {
                margin-bottom: 0;
                transition: margin-bottom 0.3s;
            }
            .logo {
                height: 48px;
                transition: height 0.3s;
            }
        }
    }
    .spacer {
        min-height: 160px;
    }
}

@import "../components/navigation";

/* Main
   ========================================================================== */

@import "../components/carousel";
.section {
    padding: 20px 0;
    @include clearfix;
    border-bottom: 1px dotted $black;
    @include breakpoint($small) {
        padding: 40px 0;
    }
    &__item {
        @include breakpoint($medium) {
        max-width: span(10);
        }
        @include breakpoint($large) {
            max-width: span(8);
        }
    }
    &__title {
        font-weight: 400;
        @include uppercase;
        color: $lightblue;
    }
    &:last-child {
        border: none;
    }
}

.mobile-section {
    clear: both;
    @include clearfix;
    @include breakpoint($xlarge) {
        display: none;
    }
    &__item {
        @include breakpoint($medium) {
            @include span(10);
        }
        @include breakpoint($large) {
            @include span(4);
            &:last-child {
                @include span(4 last)
            }
        }
    }
    &__title {
        @include font-size(24, 30);
        color: $lightblue;
        text-transform: none;
    }
}

.service {
    .row {
        clear: both;
    }
    &__item {
        @include clearfix;
        padding: 30px 0 20px;
        border-bottom: 1px solid $silver;
        &--casting {
            border-bottom: none;
        }
        @include breakpoint($large) {
            @include span(6);
            padding-right: 20px;
            border-bottom: none;
            &:last-child {
                @include span(6 last);
            }
        }
    }
    &__graphic {
        @include span(5);
        padding-right: 20px; //extra padding for smaller screens
        @include breakpoint($small) {
            @include span(4);
        }
        @include breakpoint($medium) {
            @include span(3);
        }
        @include breakpoint($large) {
            @include span(5);
        }
        @include breakpoint($xlarge) {
            @include span(4);
        }
        .graphic {}
    }
    &__wrapper {
        @include span(7 last);
        @include breakpoint($small) {
            @include span(8 last);
        }
        @include breakpoint($medium) {
            @include span(9 last);
        }
        @include breakpoint($large) {
            @include span(7 last);
        }
        @include breakpoint($xlarge) {
            @include span(8 last);
        }
    }
    &__title {
        @include font-size(18, 20);
        color: $lightblue;
        font-weight: 500;
        @include uppercase(0);
    }
}

.details {
    display: none;
}

.details.show {
    display: inline;
}

.hide {
    display: none;
}

.details-btn {
    @include button($font: $font__main, $font-size: 14, $line-height:20, $color: $lightblue, $font-weight:500, $topbottom: 0, $leftright: 0, $border-style: none, $border-color: none, $border-width: 0, $border-radius: 0, $bg-color: transparent, $cursor: pointer);
    margin-top: 10px;
    display: block;
}


/* Google Map & Contact Form */

.sub-section {
    @include clearfix;
    &__item {
        @include breakpoint($medium) {
            max-width: span(10);
        }
        @include breakpoint($large) {
            @include span(6);
        }
        &:last-child {
            @include breakpoint($large) {
                @include span(6 last);
            }
        }
    }
}

#map {
    width: 100%;
    height: 240px;
    margin: 20px 0;
    
    @include breakpoint($large){
        height: 400px;
    }
}

.form {
    background: $white;
    padding: 20px;
    &__title {
        @include font-size(24, 30);
        font-weight: 500;
        margin-bottom: 20px;
    }
    &__item {
        margin-bottom: 10px;
    }
    input,
    textarea,
    button {
        width: 100%;
        font-family: $font__main;
        padding: 10px;
        background: $lightgrey;
        border: 1px solid $grey;
        display: block; // equal space below self-closing and non self-closing tags
    }
    label {
        font-weight: 500;
    }
    .submit-btn {
        font-weight: 500;
        color: $white;
        padding: 10px;
        background: $black;
        border: none;
        transition: background-color 0.05s linear;
        border: 1px solid $grey;
        cursor: pointer;
        &:hover {
            background: lighten($black, 10%);
        }
    }
    .required {
        @include font-size(13, 13);
        color: $black;
        font-weight: 300;
    }
}


/* Footer
   ========================================================================== */

.footer {
    margin-left: -9999em;
    margin-right: -9999em;
    padding-left: 9999em;
    padding-right: 9999em;
    background-color: $lightblue;
    padding-top: 20px;
    &-inner {
        @include clearfix;
    }
    .row {
        margin: 0 -0.75%;
    }
    @include with-layout(12 0.25 inside) {
        &__item {
            @include font-size(12, 30);
            color: $lightgrey;
            @include breakpoint($small) {
                @include span(6);
            }
            @include breakpoint($large) {
                @include span(3);
            }
            @include breakpoint($xlarge) {
                @include span(3);
            }
        }
    }
    &__title {
        @include font-size(13, 30);
        font-weight: 500;
    }
    &__link {
        border-bottom: 1px solid $lightgrey;
        &:hover {
            border-bottom: none;
        }
    }
}

.sub-footer {
    margin-left: -9999em;
    margin-right: -9999em;
    padding-left: 9999em;
    padding-right: 9999em;
    background: darken($lightblue, 5%);
    @include clearfix;
    padding-top: 5px;
    padding-bottom: 5px;
    clear: both;
    &__item {
        @include font-size(12, 20);
        color: $lightgrey;
        @include breakpoint($xsmall) {
            @include span(8);
            &:last-child {
                @include span(4 last);
                text-align: right;
            }
        }
    }
    #modal-btn {
        @include button($font: $font__main, $font-size:12, $line-height:20, $color: $lightgrey, $font-weight:normal, $topbottom: 0, $leftright: 0, $border-style: none, $border-color: transparent, $border-width: 0, $border-radius: 0, $bg-color: transparent, $cursor: pointer);
    }
    .modal {
        display: none;
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
        &__content {
            text-align: left;
            @include font-size(13, 20);
            color: $black;
            font-weight: 400;
            position: relative;
            background-color: $white;
            margin: 15% auto;
            padding: 20px;
            width: 75%;
            @include breakpoint($xlarge) {
                width: 50%;
            }
            animation-name: animate;
            animation-duration: 0.2s
        }
        &__link {
            border-bottom: 1px solid $black;
            &:hover {
                border-bottom: none;
            }
        }
    }
    @keyframes animate {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .close {
        position: absolute;
        right: 20px;
        top: 10px;
        @include font-size(42, 42);
        font-weight: 300;
    }
    .close:hover,
    .close:focus {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
}

// Susy Global Defaults 
$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after,
  container: 1200px,
  container-position: center,
  columns: 12,
  gutters: .5,
  column-width: false,
  global-box-sizing: border-box,
  last-flow: to,
  debug: (
    image: false,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: true,
    rem: true,
  )
);

// color
$lightblue: #126EB8;
$blue: #19467F;
$lightgrey: #F2F2F2;
$white: #FFFFFF;
$grey: #DDDDDD;
$darkgrey: #787173;
$silver: #C0C0C0;
$silverblue: #BCC6CC;
$black: #444444;
//typgraphy
$font__main: "Rubik", "Arial", sans-serif;


//$breakpoint-to-ems: true;
$xsmall: 321px;
$small: 481px;
$medium: 601px;
$large: 769px;
$xlarge: 1025px;
